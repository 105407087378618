import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Button from 'reusecore/src/elements/Button';
import Card from 'reusecore/src/elements/Card';
import Image from 'reusecore/src/elements/Image';
import Input from 'reusecore/src/elements/Input';
import { Icon } from 'react-icons-kit';
import { iosNavigate } from 'react-icons-kit/ionicons/iosNavigate';
import FeatureBlock from '../../../components/FeatureBlock';
import Container from '../../../components/UI/Container';
import { LocationSelectorWrap } from './locationSelection.style';
import ImageThree from '../../../assets/image/ride/quantum-image-7.jpg';
import CarImage from '../../../assets/image/ride/quan-icon-1.png';
import VejpaImage from '../../../assets/image/ride/quan-icon-2.png';

const LocationSection = ({
  sectionWrapper,
  row,
  col,
  description,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  imageOne,
  imageWrapperOne,
  sectionSubTitle,
  button,
}) => {
  const [state, setState] = useState({
    active: true,
  });
  const activeStatus = state.active;
  return (
    <LocationSelectorWrap id="fare_section">
      <Box {...sectionWrapper} id="control">
        <Container fullWidth noGutter className="control-sec-container">
          <Box {...row} {...imageAreaRow}>
            

            <Box {...col} {...textArea} className="textArea">
              <div>
                <Text content="How does it work?" {...sectionSubTitle} />
                <FeatureBlock
                  description={
                    <Text
                      content="How much does GoDrive cost in your city? Calculate a fare estimate for your next trip. Simply enter a pickup location and destinationto get started. The new Driver app helps you earn smarter and supports you–like a partner–at every turn."
                      {...description}
                    />
                  }
                />
                <a href="#services">
                  <Button
                    title="Explore"
                    variant="textButton"
                    icon={<i className="flaticon-next" />}
                    {...button}
                  />
                </a>
              </div>
            </Box>
            <Box {...col} {...imageArea}>
              <Card {...imageWrapper} {...imageWrapperOne}>
                <Fade right>
                  <div style={{maxHeight: '100vh', paddingTop:'100%',position: 'relative', overflow: 'hidden'}}>
                    <Image src={ImageThree} alt="Info Image One" {...imageOne} style={{
                      height: '100%',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      objectFit: 'cover',
                      objectposition: 'center'
                    }}/>
                  </div>
                </Fade>
              </Card>
            </Box>
          </Box>
        </Container>
      </Box>
    </LocationSelectorWrap>
  );
};

LocationSection.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
};

LocationSection.defaultProps = {
  sectionWrapper: {
    as: 'section',
    pt: ['0px', '0px'],
    pb: ['0px', '0px'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  col: {
    pr: '15px',
    pl: '15px',
  },
  textArea: {
    width: ['100%', '50%', '50%', '60%', '60%'],
    p: [0, '45px'],
    m: ['30px 30px', 0],
    flexBox: true,
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageArea: {
    width: ['100%', '50%', '50%', '40%', '40%'],
    flexBox: true,
  },
  imageWrapper: {
    boxShadow: 'none',
  },
  imageWrapperOne: {
    pointerEvents: 'none',
    width: '100%',
  },
  imageOne: {
    width: '100%',
  },
  sectionSubTitle: {
    as: 'span',
    fontSize: ['22px', '26px', '26px', '30px', '36px'],
    fontWeight: '600',
    color: '#15172C',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '30px'],
    textAlign: 'center',
    fontFamily: 'Poppins',
  },
  description: {
    lineHeight: ['28px', '32px', '32px', '32px', '32px'],
    mt: ['20px', '30px', '30px', '30px', '30px'],
    mb: ['30px', '30px', '30px', '35px', '35px'],
    maxWidth: ['100%', '100%', '100%', '320px', '320px'],
    textAlign: ['left', 'left'],
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#15172C',
    fontFamily: 'Lato',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Lato',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    minHeight: '47px',
    pt: '0px',
    pb: '0',
  },
};

export default LocationSection;
